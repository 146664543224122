const TagsPageQueries = {
	getList: `
		query getAllTags{
			tags(sort: "name:ASC"){
				id
				name
			}
		}
	`,
	getFeed: `
    query getFeed(
      $tags : [ID]!
      $start : Int!,
      $limit : Int!,
    ){
      editionsConnection(
        start : $start, limit : $limit,
        where : { tags_in: $tags, archived: false },
        sort : "date:DESC"   
      ){
        values : values{
          id
          public
          title
          subtitle
          subcategory{
            id
            name
            restricted_access
            category{
              id
              name
            }
          }
          url
          date
          cover_image{
            id
            name
            previewUrl
            url
            formats
          }
          file_content
          featured
          long_tail
          content_summary
          archived
          author_editions{
            id
            main
            author {
              id
              first_name
              last_name
            }
          }
          tags{
            id
            name
          }
          published_at
        }
      }
    }
  `,
}

export default TagsPageQueries