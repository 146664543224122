import React, { Component } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter as Router } from 'react-router-dom'
import readingTime from 'reading-time'

import 'firebase/auth'
import 'firebase/performance'

import theme from './theme'
import { MuiThemeProvider, Snackbar } from '@material-ui/core'
import {
	NavBar,
	CustomDialog,
	Routes
} from './components/blocks'

import './App.css'

class App extends Component {
	_isMounted = false

	state = {
		snackbar: {
			open: false,
			autoHideDuration: 0,
			message: '',
		},

		dialog: {
			open: false,
			title: '',
			content: '',
			errorObject: '',
		},
	}

	openSnackbar = (message) => {
		this.setState({
			snackbar: {
				open: true,
				autoHideDuration: readingTime(message).time * 2,
				message,
			},
		})
	}

	closeSnackbar = (clearMessage = false) => {
		const { snackbar } = this.state

		this.setState({
			snackbar: {
				message: clearMessage ? '' : snackbar.message,
				open: false,
			},
		})
	}

	openDialog = async (errorObject, title, content) => {
		this.setState({
			dialog: {
				open: true,
				title: title || 'Oh no! Something went wrong!',
				content:
					content ||
					`Please try reloading the page. If the issue persists, please contact the tech team with the copied error message.`,
				errorObject,
			},
		})
	}

	closeDialog = (callback, clearMessage = false) => {
		const { dialog } = this.state

		this.setState(
			{
				dialog: {
					open: false,
					title: clearMessage ? '' : dialog.title,
					content: clearMessage ? '' : dialog.content,
					errorObject: clearMessage ? '' : dialog.errorObject,
				},
			},
			() => {
				if (typeof callback === 'function') {
					callback()
				}
			}
		)
	}

	render() {
		const {
			snackbar,
			dialog,
		} = this.state

		return (
			<Router>
				<CssBaseline />

				<MuiThemeProvider theme={theme}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							minHeight: '100vh',
							paddingTop: '75px',
							backgroundColor: theme.palette.primary,
						}}>

						<>
							<NavBar
								openDialog={this.openDialog}
							/>

							<Routes
								openDialog={this.openDialog}
								openSnackbar={this.openSnackbar}
							/>
						</>

						<Snackbar
							autoHideDuration={snackbar.autoHideDuration}
							message={snackbar.message}
							open={snackbar.open}
							onClose={this.closeSnackbar}
						/>

						<CustomDialog dialog={dialog} handleClose={this.closeDialog} />
					</div>
				</MuiThemeProvider>
			</Router>
		)
	}
}

export default App
