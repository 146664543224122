import React from 'react'
import { Link as RouterLink } from "react-router-dom"
import {
  Typography,
  Breadcrumbs,
  Link,
} from "@material-ui/core"

const CustomBreadcrumbs = ({ items }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link to="/" component={RouterLink}>
        Home
      </Link>

      {items.map((value, index) => {
        const last = index === items.length - 1

        return last ? (
          <Typography key={index}>
            {value.content}
          </Typography>
        ) : (
          <Link to={value.href} component={RouterLink} key={index}>
            {value.content}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default CustomBreadcrumbs