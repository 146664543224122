const EditionPageQueries = {
	getEdition: `
    query getEdition(
      $editionId : ID!
    ){
      editionsConnection(
        where : { id : $editionId },
      ){
        values : values{
          id
          title
          subtitle
          public
          subcategory{
            id
            name
            restricted_access
            description
            category{
              id
              name
            }
          }
          url
          date
          cover_image{
            id
            name
            previewUrl
            url
            formats
          }
          file_content
          featured
          long_tail
          content_summary
          archived
          author_editions{
            id
            main
            author {
              id
              first_name
              last_name
            }
          }
          tags{
            id
            name
          }
          edition_sections(sort : "position:ASC"){
            id
            position
            section{
              id
              name
            }
          }
          contents{
            id
            section{
              id
              name
            }
            position
      			title
            url
            body                   
          }
          attachment{
            id
            media{
              id
              url
              name
            }
          }
          published_at
        }
      }
    }
  `,
  // for algolia data import
	// getAllEditionsForAlgolia: `query {
	// 	editions (start: 400, sort: "id") {
	// 		id
	// 		title
	// 		date
	// 		contents {
	// 			body
	// 		}
	// 		subcategory {
	// 			id
	// 			category {
	// 				id
	// 			}
	// 		}
	// 	}
	// }`
}

export default EditionPageQueries