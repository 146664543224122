const DashboardQueries = {
  getFeatured: `
  query {
    editions(where: { archived: false, featured: true }, sort: "created_at:deSC") {
      id
      public
      title
      subtitle
      subcategory {
        id
        name
        restricted_access
        category {
          id
          name
        }
      }
      url
      date
      cover_image {
        id
        name
        previewUrl
        url
        formats
      }
      file_content
      featured
      long_tail
      content_summary
      archived
      contents(limit: 1, sort: "created_at:ASC") {
        id
        body
      }
      author_editions {
        id
        main
        author {
          id
          first_name
          last_name
        }
      }
      tags {
        id
        name
      }
      published_at
    }
  }
  `,
}

export default DashboardQueries