import React, { Component } from "react"
import {
	Container,
	withStyles,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	CircularProgress,
} from '@material-ui/core'

import { GraphqlRequest, Mixpanel } from "../../services"
import { Page, CardContainer, Info, LoadMoreButton } from '../../blocks'
import { TagsPageQueries } from "../../strapiQueries"
import { commonPageStyles, TagsStyles } from "../../styles"


const styles = (theme) => ({ ...commonPageStyles(theme), ...TagsStyles(theme) })

class Tags extends Component {
	state = {
		data: null,
		selectedTag: { id: '', name: '' },
		tagList: null,
		endCursor: 0,
		hasNextPage: false,
		loadingFeed: false,
		loadingFilteredData: false,
	}

	strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

	populateTagList = async () => {
		const {
			match: { params },
			openDialog,
		} = this.props

		try {
			const tagListRes = await this.strapiGraphqlRequestClient.request(
				TagsPageQueries.getList
			)

			this.setState(
				{
					tagList: tagListRes.tags,
					selectedTag: tagListRes.tags.find((tag) => tag.name === params.tag),
				},
				() => {
				  Mixpanel.track('Loaded a Page', {
					Title: `Tag Page - ${this.state.selectedTag.name}`
				  })
				}
			)
		} catch (error) {
			console.log('Fetching Tag List', error)
			openDialog(error)
		}
	}

	getData = async () => {
		const { openDialog } = this.props

		const { endCursor, selectedTag } = this.state

		try {
			const feedDataRes = await this.strapiGraphqlRequestClient.request(
				TagsPageQueries.getFeed,
				{
					tags: [selectedTag.id],
					start: endCursor,
					limit: 10,
				}
			)

			this.setState({
				data: feedDataRes.editionsConnection.values.filter(edition => edition.subcategory.restricted_access === false),
				endCursor: feedDataRes.editionsConnection.values.length,
				hasNextPage: feedDataRes.editionsConnection.values.length > 9,
				loadingFilteredData: false,
			})
		} catch (error) {
			openDialog(error)
		}
	}

	async componentDidMount() {
		await this.populateTagList()
		await this.getData()
	}

	componentDidUpdate(prevProps) {
		const {
			match: { params },
		} = this.props

		const { tagList } = this.state

		if (params.tag !== prevProps.match.params.tag) {
			this.setState(
				{
					selectedTag: tagList.find((tag) => tag.name === params.tag),
					loadingFilteredData: true,
					endCursor: 0,
				},
				() => {
					this.getData()
				}
			)
		}
	}

	handleChange = (event) => {
		this.setState({
			selectedTag: event.target.value,
		})

		this.props.history.push({
			pathname: `/tags/${event.target.value}`,
		})
	}

	handleLoadMore = async () => {
		const { openDialog } = this.props
		const { endCursor, selectedTag, data } = this.state

		this.setState({ loadingFeed: true })

		try {
			const feedDataRes = await this.strapiGraphqlRequestClient.request(
				TagsPageQueries.getFeed,
				{
					tags: [selectedTag.id],
					start: endCursor,
					limit: 10,
				}
			)

			const count = feedDataRes.editionsConnection.values.length

			this.setState({
				data: [...data, ...feedDataRes.editionsConnection.values.filter(edition => edition.subcategory.restricted_access === false)],
				endCursor: endCursor + count,
				hasNextPage: count > 9,
				loadingFeed: false,
			})
		} catch (error) {
			openDialog(error)
		}
	}

	render() {
		const {
			data,
			selectedTag,
			tagList,
			loadingFeed,
			hasNextPage,
			loadingFilteredData,
		} = this.state
		const {
			classes,
			match: { params },
		} = this.props

		const breadcrumbs = [
			{
				content: `Tag - ${params.tag}`,
				href: null,
			},
		]

		return (
			<Page
				className={classes.breadcrumbsContainer}
				title={`Tag - ${params.tag}`}
				breadcrumbs={breadcrumbs}>
				<Container className={classes.breadcrumbsContainer} maxWidth="lg">
					<FormControl variant="filled" className={classes.formControl}>
						<InputLabel>Select tag</InputLabel>
						<Select
							value={selectedTag.name}
							onChange={this.handleChange}
							className={classes.select}>
							{tagList &&
								tagList.map((tag) => (
									<MenuItem aria-label={tag.name} key={tag.id} value={tag.name}>
										{tag.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
					{loadingFilteredData ? (
						<CircularProgress color="primary" size={20} thickness={3} />
					) : null}
				</Container>

				{!loadingFilteredData && (
					<Container className={classes.contentContainer} maxWidth="lg">
						{data?.length < 1 ? (
							<Info
								classes={classes}
								content="No editions for this tag yet :("
							/>
						) : (
							<Container className={classes.contentContainer} maxWidth="lg">
								<CardContainer classes={classes} cardList={data} />
								<LoadMoreButton
									classes={classes}
									hasNextPage={hasNextPage}
									handleLoadMore={this.handleLoadMore}
									loadingFeed={loadingFeed}
								/>
							</Container>
						)}
					</Container>
				)}
			</Page>
		)
	}
}

export default withStyles(styles)(Tags)
