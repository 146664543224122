import React, {useEffect} from 'react'
import {
  Container,
  withStyles,
  Typography,
  Card,
  CardContent
} from "@material-ui/core"

import {
  Page
} from "../../blocks"
import { Mixpanel } from "../../services";

import { commonPageStyles } from "../../styles"

const styles = (theme) => (commonPageStyles(theme))

const breadcrumbs = [
  {
    content: "Privacy Policy",
    href: null
  },
]

const PrivacyPolicy = ({ classes }) => {

  useEffect(() => {
    Mixpanel.track("Loaded a Page", {
      Title: `Privacy Policy`,
    });
  }, []);

  return (
    <Page
      className={classes.breadcrumbsContainer}
      title={`Privacy Policy`}
      breadcrumbs={breadcrumbs}
    >
      <Container className={classes.breadcrumbsContainer} maxWidth="md">
        <Card className={classes.contentContainer} variant="elevation">
          <CardContent>
            <Typography className={classes.breadcrumbsContainer}>
              The information contained in these reports, articles, videos and other content are confidential and should not be shared publicly.
            </Typography>

            <Typography className={classes.breadcrumbsContainer}>
              Disclosure, copying and distribution is strictly prohibited. If you are not the owner of this account, please notify the Frontier Research Team immediately, and do not copy, disclose or otherwise act upon any part of the content on this platform.
            </Typography>

            <Typography className={classes.breadcrumbsContainer}>
              For operational, business and security reasons, Frontier Research reserves the right to monitor all system usage including system generated technical and analytical information that is transmitted through our network.
            </Typography>

            <Typography className={classes.breadcrumbsContainer}>
              Furthermore, the information collected/analysed is from sources believed to be reliable or from the Central Bank/Government. Frontier Research Private Limited however does not warrant its completeness or accuracy. Opinions and estimates given constitute our judgment as of the date of the material and are subject to change without notice. The reports and presentations given are not intended as an offer or solicitation for the purchase or sale of any financial instrument. The recipient of this report must make their own independent decision regarding any securities or financial instruments mentioned herein. Securities or financial instruments mentioned may not be suitable to all investors.
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default withStyles(styles)(PrivacyPolicy)