import React from 'react'
import { Switch, Route } from 'react-router-dom'
import {
	PrivacyPolicy,
	Archives,
	CC,
	CCCategory,
	CCSubcategory,
	Search,
	Tags,
	Category,
	Subcategory,
	Edition,
	Dashboard,
	PageNotFound,
} from '../../pages'

export default function Routes({ openDialog, openSnackbar }) {

	const routes = [
		{ path: '/privacy-policy', component: PrivacyPolicy },
		{ path: '/archives', component: Archives },
		{ path: '/error404', component: PageNotFound },

		{ path: '/extended-content', component: CC },
		{
			path: '/extended-content/:categoryId',
			component: CCCategory,
		},
		{
			path: '/extended-content/:categoryId/:subcategoryId',
			component: CCSubcategory,
		},
		{ path: '/search', component: Search },
		{ path: '/tags/:tag', component: Tags },
		{
			path: '/:categoryId',
			component: Category,
		},
		{
			path: '/:categoryId/:subcategoryId',
			component: Subcategory,
		},
		{
			path: '/:categoryId/:subcategoryId/:editionId',
			component: Edition,
			openSnackbar: openSnackbar,
		},
		{ path: '/', component: Dashboard },
		{ component: PageNotFound },
	]

	return (
		<Switch>
			{routes.map((route, index) => (
				<Route
					path={route.path ? route.path : null}
					render={(props) => (
						<route.component
							openDialog={openDialog}
							openSnackbar={openSnackbar}
							{...props}
						/>
					)}
					exact
					key={index}></Route>
			))}
		</Switch>
	)
}