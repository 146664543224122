import React from "react"
import { makeStyles, Container, Typography } from "@material-ui/core"
import { Link } from "react-router-dom"

import frontierLogo from "../../../images/logos/logo-frontier-research.png"
import mediumIcon from "../../../images/icons/icon-medium.svg"
import facebookIcon from "../../../images/icons/icon-facebook.svg"
import linkedinIcon from "../../../images/icons/icon-linkedin.svg"
import instagramIcon from "../../../images/icons/icon-instagram.svg"

import { FooterStyles } from "../../styles"

const useStyles = makeStyles(theme => ({ ...FooterStyles(theme) }))

export default function Footer() {
  const classes = useStyles()

  const SocialIcon = ({ href, src, alt }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <img className={classes.socialIcon} src={src} alt={alt} />
    </a>
  )

  const socialProfiles = [
    { href: "https://medium.com/frontier-research", src: mediumIcon, alt: "Medium Logo" },
    { href: "https://www.facebook.com/FrontierResearch/", src: facebookIcon, alt: "Facebook Logo" },
    { href: "https://www.linkedin.com/company/frontier-i/", src: linkedinIcon, alt: "LinkedIn Logo" },
    { href: "https://www.instagram.com/frontier.research/", src: instagramIcon, alt: "Instagram Logo" }
  ]

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <img
          className={classes.footerLogo}
          src={frontierLogo}
          alt="Frontier Research Logo"
        />

        <Typography
          style={{
            color: 'black',
            width: 'auto',
            margin: '1em',
            padding: '1em',
            border: '1px solid #CB2026',
            // backgroundColor: '#CB2026',
            textAlign: 'center',
            borderRadius: '5px'
          }}
          className={classes.subscribeMessage}
        >
          Want to have access to the rest of our research? Sign up with us for a trial!
          <br />
          Email us at <a style={{
            color: 'black'
          }} href="mailto:clientconnect@frontiergroup.info">clientconnect@frontiergroup.info</a> or give us a call on <a style={{
            color: 'black'
          }} href="tel:+94114373477">011 4373477</a> and we can set up a trial for you and  help you choose the subscription that best fits you or your team.
        </Typography>

        <Link
          className={classes.linkWrapper}
          to="/privacy-policy"
        >
          <Typography color="primary">Privacy Policy</Typography>
        </Link>

        <div className={classes.containerRight}>
          <div>
            {socialProfiles.map(({ href, src, alt }, index) => <SocialIcon key={index} href={href} src={src} alt={alt} />)}
          </div>
          <Typography className={classes.copyrightText}>
            &copy;
            {` ${new Date().getFullYear()} Frontier Research (Pvt) Ltd.`}
          </Typography>
        </div>
      </Container>
    </div>
  )
}
