function FooterStyles(theme) {
  return ({
    root: {
      width: "90%",
      borderTop: '.5px solid #ddd',
      borderBottom: '1px solid #ddd',
      padding: theme.spacing(2),
      margin: '4% auto',
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(6),
        margin: '2% auto',
      },
    },
    subscribeMessage: {
      [theme.breakpoints.up("sm")]: {
        display: 'none'
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
    },
    footerLogo: {
      width: "110px",
      marginBottom: theme.spacing(3),
    },
    containerRight: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.up("sm")]: {
        alignItems: 'flex-end',
      },
    },
    socialIcon: {
      margin: theme.spacing(0, 2),
      [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(0, 0, 0, 4),
      },
    },
    copyrightText: {
      fontSize: "1rem",
      marginTop: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(2),
      },
    },
    linkWrapper: {
      textDecoration: 'none',
      marginBottom: theme.spacing(3),
    },
  })
}

export default FooterStyles