function makeButtonLink(longTail, category, buttonID) {
  let buttonLink = []

  if (!buttonID) { // dashboard and CC
    if (!longTail) { // dashboard
      buttonLink = `/${category}`
    } else { // CC
      buttonLink = `/extended-content/${category}`
    }
  } else { // others
    if (!longTail) { // others *except* CC category and CC subcategory
      buttonLink = `/${category}/${buttonID}`
    } else { // CC category and CC subcategory
      buttonLink = `/extended-content/${category}/${buttonID}`
    }
  }

  return buttonLink
}

export default makeButtonLink