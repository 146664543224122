import React, { Component } from "react";
import { Container, withStyles, CircularProgress } from "@material-ui/core";
import {
  Page,
  CardContainer,
  ButtonContainer,
  LoadMoreButton,
  Info,
} from "../../blocks";
import { GraphqlRequest, Mixpanel } from "../../services";
import { SubcategoryPageQueries } from "../../strapiQueries";
import { commonPageStyles } from "../../styles";
import subcategoryLinks from "../../../redirectData/subcategories.json";

const styles = (theme) => commonPageStyles(theme);

class Subcategory extends Component {
  state = {
    category: null,
    subcategory: null,
    featured: [],
    feed: [],
    loadingData: true,
    hasNextPage: true,
    endCursor: 0,
    loadingFeed: false,
  };

  strapiGraphqlRequestClient = new GraphqlRequest().strapiClient;

  getInitialData = async () => {
    const {
      openDialog,
      match: { params },
      history,
      location,
    } = this.props;

    const dataPath = location.pathname.replace(/\/$/, "");

    if (dataPath in subcategoryLinks) {
      history.push(`/${subcategoryLinks[dataPath]}`);
      return;
    }

    try {
      if (isNaN(params.subcategoryId)) {
        window.location.href = "/error404";
        return;
      }

      const subcategoryDataRes = await this.strapiGraphqlRequestClient.request(
        SubcategoryPageQueries.getSubcategoryData,
        {
          subcategoryId: params.subcategoryId,
        }
      );

      if (!subcategoryDataRes.subcategory) {
        window.location.href = "/error404";
        return;
      }

      this.setState(
        {
          subcategory: subcategoryDataRes.subcategory,
          category: subcategoryDataRes.subcategory.category,
        },
        () => {
          const {
            subcategory: { name, category },
          } = subcategoryDataRes;
          Mixpanel.track('Loaded a Page', {
            title: `${name} Subcategory Page`,
            Subcategory: this.state.subcategory.name,
            Category: category,
          });
        }
      );

      const featuredDataRes = await this.strapiGraphqlRequestClient.request(
        SubcategoryPageQueries.getFeatured,
        {
          subcategoryId: params.subcategoryId,
          longTail: false,
        }
      );

      this.setState({
        featured: featuredDataRes.editionsConnection.values,
      });

      const feedDataRes = await this.strapiGraphqlRequestClient.request(
        SubcategoryPageQueries.getFeed,
        {
          subcategoryId: params.subcategoryId,
          longTail: false,
          start: 0,
          limit: 10,
        }
      );

      const count = feedDataRes.editionsConnection.values.length;

      this.setState({
        feed: feedDataRes.editionsConnection.values,
        endCursor: this.state.endCursor + count,
        hasNextPage: count > 9,
        loadingData: false,
      });
    } catch (error) {
      openDialog(error);
    }
  };

  handledialogOpen = () => {
    this.setState({ openDialog: true });
  };

  handleDialogClose = () => {
    this.setState({ openDialog: false });
  };

  handleLoadMore = async () => {
    const {
      openDialog,
      match: { params },
    } = this.props;
    const { endCursor, feed } = this.state;

    this.setState({ loadingFeed: true });

    try {
      const feedDataRes = await this.strapiGraphqlRequestClient.request(
        SubcategoryPageQueries.getFeed,
        {
          subcategoryId: params.subcategoryId,
          longTail: false,
          start: endCursor,
          limit: 10,
        }
      );

      const count = feedDataRes.editionsConnection.values.length;

      this.setState({
        feed: [...feed, ...feedDataRes.editionsConnection.values],
        endCursor: this.state.endCursor + count,
        hasNextPage: count > 9,
        loadingFeed: false,
      });
    } catch (error) {
      openDialog(error);
    }
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
    } = this.props;

    if (params.subcategoryId !== prevProps.match.params.subcategoryId) {
      this.getInitialData();
    }
  }

  render() {
    const {
      subcategory,
      category,
      featured,
      feed,
      hasNextPage,
      loadingFeed,
      loadingData,
    } = this.state;

    const {
      classes,
      match: { params },
      products,
    } = this.props;

    const breadcrumbs = [
      {
        content: category ? category.name : "Loading...",
        href: `/${params.categoryId}`,
      },
      {
        content: subcategory ? subcategory.name : "Loading...",
        href: null,
      },
    ];

    return (
      <Page
        className={classes.breadcrumbsContainer}
        title={`${subcategory ? subcategory.name : "Loading..."}`}
        breadcrumbs={breadcrumbs}
      >
        <Container className={classes.contentContainer} maxWidth="lg">
          {loadingData ? (
            <CircularProgress color="primary" size={20} thickness={3} />
          ) : (
            <>
              {!subcategory.restricted_access ? (
                <>
                  <CardContainer
                    classes={classes}
                    cardList={featured}
                    cardType="featured"
                    heading={category ? `Featured ${category.name}:` : null}
                  />
                  <ButtonContainer
                    classes={classes}
                    heading={
                      category
                        ? `Jump to other Subcategories under ${category.name}:`
                        : null
                    }
                    category={category ? category : null}
                    longTail={false}
                    currentSubcategoryId={params.subcategoryId}
                    products={products}
                  />
                  <CardContainer
                    classes={classes}
                    cardList={feed}
                    cardType="feed"
                    heading="Recent and Beyond:"
                  />
                  <LoadMoreButton
                    classes={classes}
                    hasNextPage={hasNextPage}
                    handleLoadMore={this.handleLoadMore}
                    loadingFeed={loadingFeed}
                  />
                </>
              ) : (
                <Info
                  classes={classes}
                  content={`
                    <p>Viewing this page is only available for subscribed users at the moment.</p>
                  `}
                />
              )}
            </>
          )}
        </Container>
      </Page>
    );
  }
}

export default withStyles(styles)(Subcategory);
