import React, { Component } from "react"
import { withStyles, Container } from "@material-ui/core"
import { GraphqlRequest , Mixpanel } from "../../services"
import {
  Page,
  CardContainer,
  ButtonContainer,
  LoadMoreButton,
  Info,
} from "../../blocks"
import _ from "lodash"

import { CategoryPageQueries } from "../../strapiQueries"
import { commonPageStyles } from "../../styles"
import categoryLinks from "../../../redirectData/categories.json"

const styles = (theme) => commonPageStyles(theme)

class Category extends Component {
  state = {
    category: null,
    featured: null,
    feed: null,

    hasNextPage: true,
    endCursor: 0,
    loadingFeed: false,
  }

  strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

  getInitialData = async () => {
    const {
      openDialog,
      match: { params },
      history,
      location,
    } = this.props

    const dataPath = location.pathname.replace(/\/$/, "")

    if (dataPath in categoryLinks) {
      history.push(`/${categoryLinks[dataPath]}`)
      return
    }

    try {
      if (isNaN(params.categoryId)) {
        window.location.href = "/error404"
        return
      }

      const categoryDataRes =
        await this.strapiGraphqlRequestClient.request(
          CategoryPageQueries.getCategoryData,
          {
            categoryId: params.categoryId,
          }
        )

      if (!categoryDataRes.category) {
        window.location.href = "/error404"
        return
      }

      this.setState(
        {
          category: categoryDataRes.category,
        }, 
        () => {
          Mixpanel.track('Loaded a Page', {
            Title: `${categoryDataRes.category.name} Category Page`,
            Category : `${categoryDataRes.category.name}`
					})
        }
      )

      const featuredDataRes =
        await this.strapiGraphqlRequestClient.request(
          CategoryPageQueries.getFeatured,
          {
            categoryId: params.categoryId,
            longTail: false,
          }
        )

      const data = featuredDataRes.category.subcategories

      const featuredEditions = []

      data.forEach((subcategory) => {
        featuredEditions.push(...subcategory.editions)
      })

      this.setState({
        featured: _.orderBy(featuredEditions, ["date"], ["desc"]),
      })

      const feedDataRes = await this.strapiGraphqlRequestClient.request(
        CategoryPageQueries.getFeed,
        {
          categoryId: params.categoryId,
          longTail: false,
          start: 0,
          limit: 10,
        }
      )

      const count = feedDataRes.editionsConnection.values.length
      // console.log(feedDataRes.editionsConnection.values.filter(edition => edition.subcategory.restricted_access === false))

      this.setState({
        feed: feedDataRes.editionsConnection.values.filter(edition => edition.subcategory.restricted_access === false),
        endCursor: this.state.endCursor + count,
        hasNextPage: count > 9,
      })
    } catch (error) {
      openDialog(error)
      console.log(error)
    }
  }

  handleLoadMore = async () => {
    const {
      openDialog,
      match: { params },
    } = this.props
    const { endCursor, feed } = this.state

    this.setState({ loadingFeed: true })

    try {
      const feedDataRes = await this.strapiGraphqlRequestClient.request(
        CategoryPageQueries.getFeed,
        {
          categoryId: params.categoryId,
          longTail: false,
          start: endCursor,
          limit: 10,
        }
      )

      const count = feedDataRes.editionsConnection.values.length
      // console.log(feedDataRes.editionsConnection.values.filter(edition => edition.subcategory.restricted_access === false))

      this.setState({
        feed: [...feed, ...feedDataRes.editionsConnection.values.filter(edition => edition.subcategory.restricted_access === false)],
        endCursor: this.state.endCursor + count,
        hasNextPage: count > 9,
        loadingFeed: false,
      })
    } catch (error) {
      openDialog(error)
    }
  }

  componentDidMount() {
    this.getInitialData()
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
    } = this.props

    if (params.categoryId !== prevProps.match.params.categoryId) {
      this.setState({ hasNextPage: true })

      this.getInitialData()
    }
  }

  handleDialogOpen = () => {
    this.setState({ openDialog: true })
  }

  handleDialogClose = () => {
    this.setState({ openDialog: false })
  }

  render() {
    const {
      featured,
      feed,
      category,
      hasNextPage,
      loadingFeed,
    } = this.state

    const { classes, products } = this.props

    const breadcrumbs = [
      {
        content: category ? category.name : "Loading...",
        href: null,
      },
    ]

    return (
      <Page
        className={classes.breadcrumbsContainer}
        title={`${category ? category.name : "Loading..."}`}
        breadcrumbs={breadcrumbs}
      >
        <Container
          className={classes.breadcrumbsContainer}
          maxWidth="lg"
        >
          <Info
            classes={classes}
            title="In this section:"
            content={category ? category.description : "Loading..."}
          />
        </Container>

        <Container className={classes.contentContainer} maxWidth="lg">
          <CardContainer
            classes={classes}
            cardList={featured}
            cardType="featured"
            heading={category ? `Featured ${category.name}:` : null}
          />
          <ButtonContainer
            classes={classes}
            heading={
              category
                ? `Jump to Subcategories under ${category.name}:`
                : null
            }
            category={category ? category : null}
            longTail={false}
            products={products}
          />
          <CardContainer
            classes={classes}
            cardList={feed}
            cardType="feed"
            heading="Recent and Beyond:"
          />
          <LoadMoreButton
            classes={classes}
            hasNextPage={hasNextPage}
            handleLoadMore={this.handleLoadMore}
            loadingFeed={loadingFeed}
          />
        </Container>
      </Page>
    )
  }
}

export default withStyles(styles)(Category)
