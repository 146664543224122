import { PDFDocument, rgb, StandardFonts } from "pdf-lib"
import { saveAs } from 'file-saver';


function drawText(page, mediaBox, textLine, widthOfLine, y, textSize, font) {
  page.drawText(textLine, {
    x: (mediaBox.width - widthOfLine) / 2, // X coordinate of the lower left corner of text, 
    y, // Y coordinate of the lower left corner of text,
    size: textSize, // font size,
    font, // standard font,
    color: rgb(0.204, 0.286, 0.369), // color
  })
}

export default async function addPdfWatermark(url, title) {

  const fetchedPdf = await fetch(url)
  const processedPdf = await fetchedPdf.arrayBuffer()

  const pdfDoc = await PDFDocument.load(processedPdf)
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic)

  const pages = pdfDoc.getPages()

  const textLine2 = `Sharing of this report via physical or electronic means is strictly prohibited.`
  const textSize = 11

  // We use this to center the text on each line
  const widthLine2 = helveticaFont.widthOfTextAtSize(textLine2, textSize)

  pages.forEach((page) => {
    const mediaBox = page.getMediaBox() // we get the existing mediabox from the slides

    // We add a small portion to the bottom of the slide
    page.setMediaBox(
      mediaBox.x, // X coordinate of the lower left corner of media box, 
      -40, // Y coordinate of the lower left corner of media box, 
      mediaBox.width, // width of the media box, 
      mediaBox.height + 40 // height of the media box
    )

    drawText(page, mediaBox, textLine2, widthLine2, -30, textSize, helveticaFont)
  })

  const pdfBytes = await pdfDoc.save()

  const blob = new Blob([pdfBytes], { type: "application/pdf" })
  saveAs(blob, `${title}`);

}