const ButtonQueries = {
  subcategories: `query categoryData($categoryID: ID!, $long_tail: Boolean!) {
    category(id: $categoryID) {
      subcategories(sort: "name:ASC", where: { restricted_access: false }) {
        id
        name
        editions(limit: 1, where: { archived: false, long_tail: $long_tail }) {
          id
        }
      }
    }
  }  
  `,
  categories: `
    query {
      categories{
        id
        name
      }
    }
  `
}

export default ButtonQueries