import React, { useEffect, useState, useCallback, useMemo } from "react"
import Skeleton from "@material-ui/lab/Skeleton"
import { makeStyles, Box, Button } from "@material-ui/core"
import { Link } from "react-router-dom"

import { makeButtonLink } from "../../helpers"
import { ButtonStyles } from "../../styles"
import { ButtonQueries } from '../../strapiQueries'
import { GraphqlRequest } from '../../services'

const useStyles = makeStyles(theme => ({ ...ButtonStyles(theme) }))

export default function Buttons({ current, buttonQuery, category, longTail, products }) {
	const [buttons, setButtons] = useState(null)
	const classes = useStyles()

	const strapiGraphqlRequestClient = useMemo(
		() => new GraphqlRequest().strapiClient,
		[]
	)

	const getData = useCallback(async () => {
		try {
			const res = await strapiGraphqlRequestClient.request(
				ButtonQueries.subcategories,
				{
					categoryID: category,
					long_tail: longTail,
				}
			)
			
			return res.category.subcategories.filter(subcategory => subcategory.editions.length > 0)
		} catch (err) {
			console.log(err) // GraphQL response errors
		}
	}, [category, current, longTail, strapiGraphqlRequestClient])

	useEffect(() => {
		setButtons(null)
		async function setData() {
			const data = await getData()
			setButtons(data)
		}
		setData()
	}, [category, current, buttonQuery, longTail, getData])

	return (
		<Box className={classes.buttonBox}>
			{!buttons && <Skeleton variant="rect" animation="wave" width="100%" height={500} />}

			{buttons && buttons.map((button) => (
				<Link
					key={button.id}
					className={classes.buttonLinkWrapper}
					to={makeButtonLink(longTail, category, button.id)}
				>
					<Button
						className={classes.button}
						variant="outlined"
						size="small"
						color={longTail ? "secondary" : "primary"}
					>
						{button.name}
					</Button>
				</Link>
			))}
		</Box>
	)
}
