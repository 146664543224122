import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
	Container,
	Box,
	Typography
} from "@material-ui/core"

import Buttons from '../Buttons/Buttons'
import { makeButtonLink } from '../../helpers'
import { ButtonQueries } from '../../strapiQueries'
import { GraphqlRequest } from "../../services"

export default function ButtonContainer({ classes, heading, subheading, category, longTail, currentSubcategoryId, products }) {

	const [categories, setCategories] = useState([])

	const getData = async () => {

		const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

		try {

			const res = await strapiGraphqlRequestClient.request(
				ButtonQueries.categories
			)


			setCategories(res.categories)

		} catch (err) {
			console.log(err) // GraphQL response errors
		}
	}

	useEffect(() => { if (!category) getData() }, [category])

	return (
		<Container>
			<Typography className={classes.heading} component="h2">
				{heading}
			</Typography>
			<Typography className={classes.subHeading} component="h2">
				{subheading}
			</Typography>

			<Box className={classes.buttonContainer}>
				{category ? ( // category/subcategory/CCCategory/CCSubcategory pages
					<Buttons
						category={category.id}
						longTail={longTail}
						current={currentSubcategoryId}
						products={products}
					/>
				) : (
					// CC and dashboard pages
					categories.map((category) => (
						<div key={category.id}>
							<Typography className={classes.categoryName}>
								<Link
									className={classes.linkWrapper}
									to={makeButtonLink(longTail, category.id)}>
									<span className={classes.styledSpan}>{category.name}</span>
								</Link>
							</Typography>
							<Buttons
								category={category.id}
								longTail={longTail}
								current={currentSubcategoryId}
								products={products}
							/>
						</div>
					))
				)}
			</Box>
		</Container>
	)
}