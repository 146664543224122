const drawerWidth = 240;

function NavBarStyles(theme) {
  return ({
    appBar: {
      backgroundColor: "white",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: theme.palette.common.black,
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    },
    subscribeMessage: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    logo: {
      width: '110px',
      [theme.breakpoints.down("sm")]: {
        display: 'none'
      },
    },
    menuBar: {
      backgroundColor: theme.palette.primary.main,
      fontFamily: ['Assistant', 'sans-serif'].join(', '),
      fontSize: '1.1rem',
      fontWeight: '600',
      minHeight: '35px',
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down("sm")]: {
        display: 'none'
      },
    },
    menuBarLink: {
      color: '#ffffff',
      padding: theme.spacing(0.5, 5),
      transition: 'all .3s',
      "&:hover": {
        textDecoration: 'none',
        backgroundColor: '#ffffff',
        color: theme.palette.primary.main
      }     
    },
    drawerLink: {
      "&:hover": {
        textDecoration: "none"
      }
    },
    hide: {
      display: 'none',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'normal',
      }
    },
    drawerOpen: {
      width: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }
    },
    drawerClose: {
      [theme.breakpoints.up('sm')]: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9) + 1,
        },
      }
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    drawerListItem: {
      paddingLeft: theme.spacing(7)
    },
    form: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    addButton: {
      position: "relative",
    },
    navIcon: {
      marginRight: theme.spacing(2),
    },
    popper: {
      zIndex: theme.zIndex.drawer + 2,
      '&[x-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        marginTop: '-0.9em',
        width: '3em',
        height: '1em',
        '&::before': {
          borderWidth: '0 1em 1em 1em',
          borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
        },
      }
    },
    arrow: {
      position: 'absolute',
      fontSize: "7px",
      width: "3em",
      height: '3em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
      },
    }
  })
}

export default NavBarStyles