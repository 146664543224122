
import mixpanel from 'mixpanel-browser';

let env_check = process.env.NODE_ENV === 'production';

if(env_check){
    mixpanel.init('f4862244f1c2b762f3ee5d19d719db8e', {ignore_dnt: true});
}else{
    mixpanel.init('f4862244f1c2b762f3ee5d19d719db8e', {debug: true, ignore_dnt: true}); 
}

//uncomment below line to check event in development 
// env_check = true

let trackCalls = {
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
};

export default trackCalls;