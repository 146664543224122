import React, { Component } from "react"
import { Link as RouterLink } from "react-router-dom"
import {
	withStyles,
	Container,
	Link
} from "@material-ui/core"

import { GraphqlRequest, Mixpanel } from "../../services"
import {
	Page,
	CardContainer,
	ButtonContainer
} from "../../blocks"
import { DashboardQueries } from "../../strapiQueries"
import { commonPageStyles } from "../../styles"

const styles = (theme) => ({ ...commonPageStyles(theme) })

class Dashboard extends Component {
	state = {
		ccData: null,
		nonCCData: null,
	}

	strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

	getData = async () => {
		const { openDialog } = this.props

		let cc = []
		let nonCC = []

		try {
			const { editions } = await this.strapiGraphqlRequestClient.request(
				DashboardQueries.getFeatured,
			)

			const unrestrictedEditions = editions.filter(edition => edition.subcategory.restricted_access === false)

			unrestrictedEditions.map((edition) => {
				if (edition.long_tail) {
					return cc.push(edition)
				} else {
					return nonCC.push(edition)
				}
			})

			this.setState({
				ccData: cc,
				nonCCData: nonCC,
			})
		} catch (err) {
			openDialog(err)
			console.log(err) // GraphQL response errors
		}
	}

	componentDidMount() {
		this.getData()

          Mixpanel.track('Loaded a Page', {
            Title: `Home Page`
          })
	}

	render() {
		const { ccData, nonCCData } = this.state

		const { classes, products } = this.props

		return (
			<Page title="Home">
				<Container className={classes.contentContainer} maxWidth="lg">
					<CardContainer
						classes={classes}
						cardList={nonCCData}
						cardType="feed"
						heading="Featured"
					/>
					<ButtonContainer
						classes={classes}
						heading="Want to Dive Deeper? Jump to Our Subcategories"
						longTail={false}
						products={products}
					/>
					<CardContainer
						classes={classes}
						cardList={ccData}
						cardType="feed"
						heading={
							<span>
								Feeling Curious? Head to the{' '}
								<Link
									to="/extended-content"
									component={RouterLink}
									color="secondary">
									Curious Corner!
								</Link>
							</span>
						}
						subheading="Check out a curated collection of additional research and content from us that you may find interesting!"
					/>
					<ButtonContainer
						classes={classes}
						subheading="Subcategories under Curious Corner:"
						longTail={true}
						products={products}
					/>
				</Container>
			</Page>
		)
	}
}

export default withStyles(styles)(Dashboard)
