function makeBreadcrumbs(longTail, archived, editionData, params) {
  let breadcrumbItems = []

  if (archived) {
    breadcrumbItems = [
      {
        content: "Archives",
        href: "/archives"
      },
      {
        content: editionData ? editionData.title : "Loading...",
        href: null
      },
    ]
  } else if (longTail) {
    breadcrumbItems = [
      {
        content: 'Curious Corner',
        href: '/extended-content',
      },
      {
        content: editionData
          ? `Curious Corner - ${editionData.subcategory.category.name}`
          : 'Loading...',
        href: `/extended-content/${params.categoryId}`,
      },
      {
        content: editionData ? editionData.title : 'Loading...',
        href: null,
      },
    ]
  } else {
    breadcrumbItems = [
      {
        content: editionData ? editionData.subcategory.category.name : "Loading...",
        href: `/${params.categoryId}`
      },
      {
        content: editionData ? editionData.subcategory.name : "Loading...",
        href: `/${params.categoryId}/${params.subcategoryId}`
      },
      {
        content: editionData ? editionData.title : "Loading...",
        href: null
      },
    ]
  }

  return breadcrumbItems
}

export default makeBreadcrumbs