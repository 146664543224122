import settings from "../../settings"
const { GraphQLClient } = require('graphql-request')


class GraphqlRequest {
  constructor() {
    this.strapiClient = new GraphQLClient(`${settings.strapiURL}/graphql`)
  }
}

export default GraphqlRequest