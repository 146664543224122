import React from "react"
import { Link } from "react-router-dom"
import {
	Card,
	CardContent,
	Typography,
	Box,
	CardMedia,
	Chip,
	Tooltip
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"
import moment from "moment"
import pluralize from "pluralize"
import _ from "lodash"

import { makeAuthorString } from '../../helpers'
import { commonCardStyles, FeedCardStyles } from "../../styles"
import { Opacity } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({ ...commonCardStyles(theme), ...FeedCardStyles(theme) }))

export default function FeedCard({ cardData, category }) {
	const classes = useStyles()

	return (
		<>
			{!cardData && <Skeleton variant="rect" width="100%" />}

			{
				// if public, show no tooltip - if public show tooltip text
			}
			<Tooltip
				title={cardData.public ? "" : "Viewing item is only available for subscribed users at the moment."}
				classes={{ tooltip: classes.tooltipStyles }}
				placement="top-end"
			>

				<Link
					style={{
						opacity: cardData.public ? 1 : .4,
						cursor: cardData.public ? 'pointer' : 'default' // if public, show pointer - else, default
					}}
					to={
						cardData.public ? `/${category.id}/${cardData.subcategory.id}/${cardData.id}` : ''
					}
					onClick={e => { !cardData.public && e.preventDefault() }} // if not public, don't follow link
					onContextMenu={e => { !cardData.public && e.preventDefault() }} // if not public, don't show right-click menu
					className={classes.linkWrapper}
				>
					<Card className={classes.root} variant="elevation">
						<CardContent className={classes.textContent}>
							<Box className={classes.cardInfoBox}>
								<div className={classes.cardInfoTopRow}>
									<Chip
										label={_.upperCase(cardData.subcategory.name)}
										className={`${classes.cardInfoText} ${classes.badge}`}
										size="small"
										variant="outlined"
									/>
									<Typography
										className={`${classes.cardInfoText} ${classes.categoryText}`}>
										{pluralize.singular(category.name)}
									</Typography>
								</div>
								<Typography
									className={classes.cardInfoText}
									style={{ marginLeft: 'auto' }}>
									{ // display new date for "Here's where to find"
										cardData.id === "266" 
											? moment(new Date()).format('Do MMM YYYY')
											: moment(
													cardData.date ? cardData.date : cardData.published_at
												).format('Do MMM YYYY')
									}
								</Typography>
							</Box>
							<Typography
								component="h2"
								className={classes.cardTitle}
								gutterBottom>
								{cardData.title}
							</Typography>
							<Typography className={classes.cardSubTitle} gutterBottom>
								{cardData.subtitle}
							</Typography>
							<Box className={classes.cardInfoBox}>
								<Typography className={classes.cardInfoText}>
									{makeAuthorString(
										cardData.author_editions
											.filter((item) => item.main)
											.map((item) => item.author),
										cardData.author_editions
											.filter((item) => !item.main)
											.map((item) => item.author),
										cardData.subcategory
									)}
								</Typography>
							</Box>
						</CardContent>
						<Box className={classes.mediaContent}>
							<CardMedia
								component="img"
								className={classes.media}
								alt={`Cover Image: ${cardData.title}`}
								title={`Cover Image: ${cardData.title}`}
								image={cardData.cover_image ? cardData.cover_image.url : null}
							/>
							<div></div>
						</Box>
					</Card>
				</Link>
			</Tooltip>
		</>
	)
}

