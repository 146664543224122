import React, { useEffect, useState, useCallback } from "react"
import { Link as RouterLink, withRouter } from 'react-router-dom'
import {
  makeStyles,
  AppBar,
  Toolbar,
  Link,
  IconButton,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core"

import frontierLogo from "../../../images/logos/frontier-logo-with-padding.png"

import {
  Menu as MenuIcon,
  Search as SearchIcon,
} from "@material-ui/icons"

import { NavBarStyles } from "../../styles"
import DrawerMenu from "./DrawerMenu"
import { GraphqlRequest } from '../../services'


const useStyles = makeStyles((theme) => (NavBarStyles(theme)))

function NavBar(props) {
  const [menuItems, setMenuItems] = useState([])
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  const { openDialog } = props

  const fetchCategories = useCallback(async () => {

    const strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

    try {
      const fetchCategoriesList = await strapiGraphqlRequestClient.request(`
        query{
          categories(publicationState: LIVE ) {
            id
            name
            published_at
          }
        }
      `)

      const data = fetchCategoriesList.categories

      // hashmap to store categories from db { categoryID: { name, link }, ... }
      let categories = {}
      
      let menuItems = []

      // populate 'categories'
      data.forEach((category) => {
        categories[category.id] = {
          name: category.name,
          link: `/${category.id}`,
        }
      })
      
      menuItems[0] = categories[1] // core reports
      menuItems[1] = categories[3] // frontier videos
      menuItems[2] = categories[5] // webinar summary notes
      menuItems[4] = { name: 'Curious Corner', link: '/extended-content' }
      menuItems[5] = categories[4] // grab bag
      menuItems[6] = { name: 'Archives', link: '/archives', }

      setMenuItems(menuItems)
    } catch (error) {
      openDialog(error)
    }
  }, [openDialog])


  useEffect(() => {
    async function fetchCategoryData() {
      await fetchCategories()
    }
    fetchCategoryData()
  }, [fetchCategories])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const goToSearch = () => {
    props.history.push({
      pathname: "/search"
    })
  }

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={`${classes.appBar} ${open ? classes.appBarShift : null}`}>
        <Toolbar style={{ display: 'flex', justifyContent: "space-between" }}>
          <Link to='/' component={RouterLink}>
            <img
              className={classes.logo}
              src={frontierLogo}
              alt="Frontier Research Logo"
            />
          </Link>

          <IconButton
            className={`${classes.menuButton} ${open ? classes.hide : null}`}
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            style={{
              color: 'black',
              width: 'auto',
              margin: '1em',
              padding: '1em',
              border: '1px solid #CB2026',
              // backgroundColor: '#CB2026',
              textAlign: 'center',
              borderRadius: '5px'
            }}
            className={classes.subscribeMessage}
          >
            Want to have access to the rest of our research? Sign up with us for a trial!
            <br />
            Email us at <a style={{
              color: 'black'
            }} href="mailto:clientconnect@frontiergroup.info">clientconnect@frontiergroup.info</a> or give us a call on <a style={{
              color: 'black'
            }} href="tel:+94114373477">011 4373477</a> and we can set up a trial for you and  help you choose the subscription that best fits you or your team.
          </Typography>

          <div className="icons">
            <IconButton
              color="default"
              aria-label="search"
              onClick={goToSearch}
              className={classes.navIcon}
            >
              <SearchIcon />
            </IconButton>
          </div>
        </Toolbar>

        <Toolbar className={classes.menuBar}>
          {
            menuItems.map(menuItem => (
              <Link to={menuItem.link} className={classes.drawerLink} component={RouterLink} key={menuItem.name}>
                <ListItem className={classes.menuBarLink}>
                  <ListItemText disableTypography primary={menuItem.name} />
                </ListItem>
              </Link>
            ))
          }
        </Toolbar>
      </AppBar>

      <div className={classes.toolbar} />
      <div className={classes.toolbar} />

      <DrawerMenu
        classes={classes}
        handleDrawerClose={handleDrawerClose}
        open={open}
        menuItems={menuItems}
      />
    </div>
  )
}

export default withRouter(NavBar)
