import React, { Component } from "react"
import { Container, withStyles, Box } from "@material-ui/core"

import {
  Page,
  ArticleCard,
  Info,
} from "../../blocks"
import { makeBreadcrumbs } from "../../helpers"
import { GraphqlRequest, Mixpanel } from "../../services"
import { EditionPageQueries } from "../../strapiQueries"
import { EditionStyles, commonPageStyles } from "../../styles"
import editionLinks from "../../../redirectData/editions.json"

const styles = (theme) => ({
  ...EditionStyles(theme),
  ...commonPageStyles(theme),
})

class Edition extends Component {
  state = {
    editionData: null,
    longTail: null,
    archived: null,
  }

  strapiGraphqlRequestClient = new GraphqlRequest().strapiClient

  getEdition = async () => {
    const {
      match: { params },
      openDialog,
      history,
      location,
    } = this.props

    const dataPath = location.pathname.replace(/\/$/, "")

    if (dataPath in editionLinks) {
      history.push(`/${editionLinks[dataPath]}`)
      return
    }

    try {
      if (isNaN(params.editionId)) {
        window.location.href = "/error404"
        return
      }
      const editionRes = await this.strapiGraphqlRequestClient.request(
        EditionPageQueries.getEdition,
        {
          editionId: params.editionId,
        }
      )
      // const res = await this.strapiGraphqlRequestClient.request(EditionPageQueries.getAllEditionsForAlgolia);
      // console.log(res.editions)

      const data = editionRes.editionsConnection.values

      if (!data.length) {
        window.location.href = "/error404"
        return
      }


      this.setState(
        {
          editionData: data[0],
          longTail: data[0].long_tail,
          archived: data[0].archived,
        },
        () => {
          Mixpanel.track('Loaded a Page', {
            Title: `${this.state.editionData.title}`
          })
        }
      )
    } catch (err) {
      openDialog(err)
      console.log(err) // GraphQL response errors
    }
  }

  componentDidMount() {
    this.getEdition()
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
    } = this.props

    if (params.editionId !== prevProps.match.params.editionId) {
      this.getEdition()
    }
  }

  handleDialogOpen = () => {
    this.setState({ openDialog: true })
  }
  handleDialogClose = () => {
    this.setState({ openDialog: false })
  }

  render() {
    const {
      editionData,
      longTail,
      archived,
    } = this.state
    const {
      classes,
      match: { params },
      user,
      openSnackbar,
    } = this.props

    return (
      <Page
        className={classes.breadcrumbsContainer}
        title={`${editionData ? editionData.title : "Loading..."}`}
        breadcrumbs={makeBreadcrumbs(
          longTail,
          archived,
          editionData,
          params
        )}
      >
        <Container maxWidth="lg">
          {(editionData && !editionData.subcategory.restricted_access && editionData.public) ? (
            <>
              <Box className={classes.pageWrapper}>
                <ArticleCard
                  cardData={editionData}
                  category={
                    editionData
                      ? editionData.subcategory.category
                        .name
                      : null
                  }
                  classes={classes}
                  user={user}
                  openSnackbar={openSnackbar}
                />
              </Box>
            </>
          ) : (
            <Info
              classes={classes}
              content={`
                  <p>Viewing this report is only available for subscribed users at the moment.</p>
              `}
            />
          )}
        </Container>
      </Page>
    )
  }
}

export default withStyles(styles)(Edition)
